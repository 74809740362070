<template>
    <section>
        <base-card>
          <!--Intro-Text-->
          <v-container
              class="mb-4"
          >
            <v-skeleton-loader
                type="paragraph@3"
                :loading="loading"
            >
              <template v-slot:default>
                <v-card-text
                    id="about"
                    class="text-xs-center text-lg-justify text-lg-h6 text-sm-subtitle-1 font-weight-light mx-auto mt-5"
                    v-html="text"
                >
                </v-card-text>
              </template>
            </v-skeleton-loader>
          </v-container>
          <!--Kontaktanfrage-->
          <v-card-title
              id="contact"
              :class="[$vuetify.breakpoint.mdAndUp ? 'display-3' : 'headline']"
              class="justify-center font-weight-bold tex--darken-3 pb-0 mt-md-3 text-uppercase logo"
          >
            {{$t('contact.contactForm')}}
          </v-card-title>
          <v-container
              class="mb-4 pt-0"
          >
            <v-skeleton-loader
                type="paragraph@1"
                :loading="loading"
            >
              <template v-slot:default>
                <v-card-text
                    class="text-xs-center text-lg-justify text-lg-h6 text-sm-subtitle-1 font-weight-light mx-auto my-5"
                >
                  {{$t('contact.contactText')}}
                </v-card-text>
              </template>
            </v-skeleton-loader>
          <contact-form/>
          </v-container>
          <!--Dokumente für Mitglieder-->
          <!--Parallax-->
          <v-parallax
              :height="$vuetify.breakpoint.mdAndUp ? 800 : 300"
              :lazy-src="`${require('@/assets/img/gladbeck_gruppenfoto.jpg')}?h=500&fm=jpg&fl=progressive&q=50`"
              :src="`${require('@/assets/img/gladbeck_gruppenfoto.jpg')}?h=1200&fm=jpg&fl=progressive&q=100`"
          >
            <v-overlay
                value="true"
                absolute
            >
              <v-container fluid>
                <v-row justify="center">
                  <v-col
                      class="font-weight-bold white--text text-uppercase headline logo"
                  >
                    #JEMEINSAM
                  </v-col>
                </v-row>
              </v-container>
            </v-overlay>
          </v-parallax>
        </base-card>
    </section>
</template>

<script>
    import i18n from '@//plugins/i18n'
    import ContactForm from "@/components/contact/ContactForm"
    import showdown from "showdown"
    import {fetchData, query_CONTACTTEXT} from "@/utils/api"

    export default {
        components: {ContactForm},
      data: () => ({
        loading: true,
        text: {}
      }),
        computed: {
            currentLang () {
                return i18n.locale
            }
        },
      methods: {
        getText: async () => {
          const query = query_CONTACTTEXT(i18n.locale)
          return await fetchData(query)
        }
      },
      async created() {
        const text = await this.getText()
        const converter = new showdown.Converter()
        this.text = converter.makeHtml(text.textContent.text)
        this.loading = false
      }
    }
</script>
